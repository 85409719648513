#Receipt {
    width: 50vw;
    background: var(--bg);
    position: fixed;
    inset: 0;
    height: 90dvh;
    margin: auto;
    z-index: 100;
    display: grid;
    grid-template-columns: .6fr .4fr;
    border-radius: 5px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: .4s;
}
#Receipt.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);

}
#Receipt img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#Receipt .image {
    height: 60vh;
}
#Receipt button {
    font-family: jannaRegular;
    margin: .2rem 0;
    background: var(--primary);
    color: var(--bg);
}
#Receipt button:hover {
    background: var(--accent);
}

#Receipt .btns {
    padding: 0 .5rem;
    display: flex;
    flex-direction: column;
    justify-content: end;
}