.alert {
    background: var(--bg);
    position: fixed;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    padding: 1rem;
    border: 1px solid var(--bg-darkest);
    border-radius: 5px;
    box-shadow:  0 0 10px rgba(0, 0, 0, 0.065);
    opacity: 0;
    transition: .4s;
    pointer-events: none;
    transform: translateY(10px);
    z-index: 100;
}
.alert.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}
.alert .head {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.alert .icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(212, 59, 59);
    background-color: rgb(212, 59, 59, 0.1);
    border-radius: 4px;
    font-size: var(--title);
}
.alert h3 {
    color: rgb(212, 59, 59);
    font-size: var(--title)
}
.alert .message p {
    margin-top: .5rem;
    opacity: .9;
    font-size: var(--paragraph);
}

.alert .buttons {
    display: flex;
    align-items: center;
    gap: .6rem;
    margin-top: 1rem;
}
.alert .buttons button {
    width: fit-content;
    font-family: JannaRegular;
    transition: .4s ease;
    font-size: var(--paragraph);
    cursor: pointer;
    border: none;
    outline: none;
    padding: .7rem 1.5rem;
    border-radius: 5px;
}
.alert .buttons .do {
    background: rgb(212, 59, 59);
    color: var(--bg);
}
.alert .buttons .do:hover {
    filter: brightness(110%);
}
.alert .buttons .dont {
    color: rgb(212, 59, 59);
}

.shadow {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0;
    z-index: 99;
    transition: .4s ease;
    pointer-events: none;
}
.shadow.active {
    opacity: .2;
    pointer-events: all;
}