.options ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    border-bottom: 1px solid var(--bg-darkest);
}
.options ul li {
    padding: 1rem;
    font-size: var(--paragraph);
    transition: .4s ease;
    border-bottom: .5px solid var(--bg-darkest);
    cursor: pointer;
}
.options ul li.active {
    border-color: var(--primary);
}
.isEdited {
    background: var(--primary) !important;
    width: fit-content;
    padding: .25rem 2rem;
    border-radius: 25px;
    color: var(--bg);
}

table {
    position: relative;
}
.sticky-thead {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
}