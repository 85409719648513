.edit-session-container {
    position: fixed;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    background: var(--bg);
    border: 1px solid var(--bg-darker);
    z-index: 100;
    padding: 1.5rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: .4s;
    border-radius: 5px;
}
.edit-session-container.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}
.edit-session-container h2 {
    font-family: JannaBold;
}

