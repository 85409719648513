.cosmetics-page .head-section ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--bg-darkest);
    margin-top: 2rem;
    position: relative;
}
.cosmetics-page .head-section ul li:not(.hover) {
    padding: 1.3rem;
    transition: .4s ease;
    cursor: pointer;
    font-size: var(--paragraph);
}
.cosmetics-page .head-section ul li:hover {
    color: var(--primary);
}
.cosmetics-page .head-section ul li.hover {
    width: 100px;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--primary);
    transition: .4s ease;
}
.cosmetics-page .head-section ul li.hover.step-2 {
    right: 108px;
}

.cosmetics-page .cosmeticsPricesRow {
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(3, 1fr);
}
.cosmetics-page table button {
    color: var(--bg);
    background: var(--primary) !important;
}

.cosmetics-page .cosmeticsPricesRow.storage-row {
    grid-template-columns: 10% repeat(3, 1fr);   
}