.one-receipt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
}
.one-receipt .image {
    height: fit-content;
    border-radius: 6px;
    overflow: hidden;
}
.one-receipt img {
    width: 100%;
}
.one-receipt section:nth-child(1) {
    border-bottom: 1px solid var(--bg-darkest);
    padding-bottom: 2rem;
}
.one-receipt section .title {
    font-size: calc(var(--title) *1.5);
    font-family: jannaBold;
    margin-bottom: 1.5rem;
}
.one-receipt section .section-content {
    margin-top: 2rem;
}
.one-receipt section .section-content h2 {
    font-size: calc(var(--title) *.9);
}
.one-receipt section .section-content h1 {
    font-size: calc(var(--title) *1.25);
}
.one-receipt section .section-content ul {
    list-style: none;
}
.one-receipt section .section-content ul li {
    margin: .6rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.one-receipt section .section-content ul li span {
    font-weight: 600;
}
.one-receipt section .section-content ul li::before {
    width: 7px;
    height: 7px;
    display: block;
    content: '';
    background-color: var(--primary);
    border-radius: 50%;
    border-top-right-radius: 0;
}
.one-receipt section .section-content button {
    width: 100%;
    display: block;
    padding: 1.4rem 2rem;
    background: var(--primary);
    color: var(--bg);
    transition: .4s;
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: JannaRegular;
    margin: .4rem 0;
}
.one-receipt section .section-content button:hover {
    background-color: var(--accent);
}