.receipt-paper {
    border: 1px solid #d9d9d9;
    padding: .8rem;
    width: 100%;
    max-width: 5.8in;
    min-height: 100%;
}
.receipt-paper .reciepe-receipt-paper {
    display: flex;
    align-items:center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
}
.receipt-paper .reciepe-receipt-paper .logo {
    width: 1.5cm;
}
.receipt-paper .reciepe-receipt-paper .logo img {
    width: 100%;
}
.receipt-paper .reciepe-receipt-paper .dates {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: 14px;
}
.receipt-paper .reciepe-receipt-paper .hour {
    padding-left: 1rem;
    border-left: 1px solid #d9d9d9;
}
.receipt-paper .content ul {
    list-style-type: none;
}
.receipt-paper .content ul li {
    display: flex;
    text-align: start;
    justify-content: end;
    flex-direction: row-reverse;
    align-items: center;
    gap: .3rem;
    padding: 1rem;
}
.receipt-paper .content ul li:nth-child(even){
    background-color: rgb(245, 245, 245);
}
.receipt-paper .content ul li .label {
    font-weight: 500;
}
.receipt-paper .content ul li .text {
    text-decoration: underline;
}
.receipt-paper .signatures {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: end;
    margin: .7rem 0;
    padding: .7rem 0;
    text-align: right;
    border-top: 1px solid #d9d9d9;
}
.receipt-paper .notes ul {
    list-style-type: none;
    text-align: right;
}
.receipt-paper .notes ul li {
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: right;
    margin: .6rem 0;
}
.receipt-paper .notes ul li::after {
    width: 8px;
    height: 8px;
    display: block;
    content: '';
    background-color: #228A83;
    border-radius: 50%;
    border-top-right-radius: 0;
}
.receipt-paper .contacts {
    padding: 1rem 0;
    border-top: 1px solid #d9d9d9;
}
.receipt-paper .contacts .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.receipt-paper .contacts .row .socials .icons {
    display: flex;
}
.receipt-paper .contacts .row .socials {
    display: flex;
    gap: .5rem;
}
.receipt-paper .contacts .row .socials .icons {
    gap: .1rem;
}
.receipt-paper .contacts .row .socials .icons div {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f2f2f2;
    background: #228A83;
    border-radius: 50%;
}
.receipt-paper .contacts .row .location {
    display: flex;
    gap: .6rem;
    flex-direction: row-reverse;
    margin-top: 1rem;
}
.receipt-paper .contacts .row .location i {
    color: #228A83;
}
.receipt-paper .contacts .phone {
    display: flex;
    align-items: center;
    gap: .6rem;
}
.receipt-paper .contacts .phone i {
    color: #228A83;
}
