.sidebar {
    height: 100vh;
    position: relative !important;
}
.sidebar .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.sidebar .logo .img {
    width: 60px;
    margin-right: auto;
}
.sidebar .logo .logout {
    font-size: var(--title);
    opacity: .8;
    transition: .4s;
}
.sidebar .logo .logout:hover {
    color: rgb(238, 92, 92);
}
.sidebar .logo img {
    width: 100%;
}
.sidebar .content {
    padding: 1rem;
    width: 20vw;
    border-left: 1px solid var(--bg-darkest);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    overflow: scroll;
}
.sidebar .content::-webkit-scrollbar-thumb {
    background: var(--accent);
}
.sidebar h3 {
    font-size: var(--title);
    font-family: JannaBold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sidebar h3 .openIcon {
    width: 14px;
    height: 14px;
    position: relative;
    display: block;
}
.sidebar h3 .openIcon div {
    width: 1px;
    height: 100%;
    display: block;
    background-color: var(--font);
    position: absolute;
    inset: 0;
    transition: .4s ease;
    margin: auto;
}
.sidebar h3 .openIcon div:nth-child(2){
    transform: rotate(90deg);
}
.sidebar section {
    margin: 1rem 0;
}
.sidebar section.isOpen h3 .openIcon div:nth-child(1){
    transform: rotate(90deg);
}
.sidebar ul {
    max-height: 0;
    overflow: hidden;
    list-style-type: none;
    margin-top: .3rem;
    transition: .4s ease;
}
.sidebar section.isOpen ul {
    max-height: 1000px;
}
.sidebar ul li {
    margin: .2rem 0;
    border-radius: 5px;
    cursor: pointer;
    transition: .4s ease;
    font-size: var(--paragraph);
}
.sidebar ul li a {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    align-items: center;
}
.sidebar ul li:hover {
    background: var(--bg-darker);
}
.sidebar ul li .icon {
    transition: .4s ease;
    height: 20px;
}
.sidebar ul li:hover .icon {
    color: var(--primary);
}