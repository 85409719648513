.after-notes {
    position: fixed;
    top: .5rem;
    right: var(--container);
    width: 30vw;
    height: fit-content;
    display: block;
    background: var(--bg);
    border: var(--bg-darker) 1px solid;
    padding: 1rem;
    border-radius: 5px;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: .4s;
}
.after-notes.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}