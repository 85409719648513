.form-page .form {
    margin: 3rem 0;
}
.form-page .form .field-container {
    position: relative;
}
.form-page .form .field-container.disabled {
    opacity: .7;
}
.form-page .form .row {
    margin: .5rem 0;
}
.form-page .form label {
    display: block;
    position: absolute;
    top: 1.4rem;
    transition: .4s ease;
    right: .5rem;
    background: var(--bg);
    line-height: var(--paragraph);
    font-size: var(--paragraph);
    padding:0 .4rem;
    pointer-events: none;
}
.form-page .form .field-container.red label{
    color: var(--danger);
}
.form-page :is(.field, button, .dropdown-container) {
    width: 100%;
    padding: 1rem;
    background-color: transparent;
    border: 1px solid var(--bg-darkest);
    border-radius: 5px;
    font-size: var(--paragraph);
    transition: .4s ease;
    outline: none;
}
.form-page .field {
    filter: brightness(90%);
}
.form-page textarea {
    min-height: 300px;
    max-height: 450px;
    width: 100%;
    resize: vertical;
    transition: 0 resize;
}
.form-page .field:hover {
    filter: brightness(80%);
}
.form-page  :is(.field:focus, .field.active, .dropdown-container.active) {
    border-color: var(--primary);
}

.form-page .form .field-container.red :is(.field:focus, .field.active, .dropdown-container.active){
    border-color: var(--danger);
}
.form-page :is(.field:focus + label, label.active) {
    font-size: calc(var(--paragraph) * .9);
    top: -.4rem;
    color: var(--primary);
    opacity: 1;
}
.form-page .form button {
    /* padding: 1.2rem !important; */
    font-family: JannaRegular;
    background: var(--primary) !important;
    color: #f2f2f2;
    transform: .3s;
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
    flex-direction: row-reverse;
}
.form-page .form button.cancel {
    border-color: var(--bg-darkest);
    color: var(--danger);
    background: none !important;
}
.form-page .form button:hover {
    filter: brightness(110%);
}
.form-page .form button.cancel:hover {
    background: var(--bg-darker) !important;
}
.form-page .form .grid-1 {
    display: grid; gap: .5rem;
    grid-template-columns: calc(65% - .5rem) calc(15% - .5rem) calc(10% - .5rem) calc(10% - .5rem);
}
.form-page.add-staff .form .grid-1 {
    grid-template-columns: calc(75% - .5rem) calc(15% - .5rem) calc(10% - .5rem);
}
.form-page .form .grid-2 {
    display: grid; gap: .5rem;
    grid-template-columns: repeat(2, calc(50% - .5rem));
}
.form-page .form .grid-3 {
    display: grid; gap: .5rem;
    grid-template-columns: repeat(2, calc(50% - .5rem));
}
.form-page.add-staff .form .grid-3 {
    grid-template-columns: repeat(3, calc(33.3333333333333333333333333333333333333333333% - .5rem));
}
.form-page .form .grid-4 {
    display: grid; gap: .5rem;
    grid-template-columns: repeat(5, calc(20% - .5rem));
}
.form-page .dropdown {
    /* display: none; */
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--bg);
    border: 1px solid var(--bg-darkest);
    border-radius: 4px;
    padding: .6rem;
    z-index: 10;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: .4s ease;
}
.form-page .dropdown.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}
.form-page .dropdown:after {
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    top: calc(12px/-2);
    right: 1.6rem;
    transform: rotate(45deg);
    margin: auto;
    background: var(--bg);
    border: 1px solid var(--bg-darkest);
    border-bottom: none;
    border-right: none;
    content: '';
}
.form-page .dropdown ul {
    list-style-type: none;
}
.form-page .dropdown ul li {
    padding: .2rem;
    cursor: pointer;
}
.form-page .dropdown-container {
    height: fit-content;
    cursor: pointer;
    position: relative;
}


.form-page .row .view-password-button {
    position: absolute;
    top: 0;
    left: .5rem;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.form-page .filter.patients {
    display: grid;
    grid-template-columns: .6fr .2fr .2fr;
    gap: .5rem;
    margin-top: 2rem;
}
.form-page .filter.patients .search-bar-grid:hover .icon {
    filter: brightness(95%);
}
.form-page .filter.patients .search-bar-grid .icon:hover {
    background: var(--bg);
    color: var(--primary);
    cursor: pointer;
}
.form-page .filter.patients .search-bar-grid {
    display: grid;
    grid-template-columns: 65px auto;
}
.form-page .filter.patients .field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.form-page .filter.patients .icon {
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid var(--bg-darkest);
    filter: brightness(90%);
    border-top-left-radius: 0;
    transition: .4s ease;
    border-bottom-left-radius: 0;
    border-left: 0;
}
.form-page .filter.patients label {
    display: block !important;
    position: initial !important;
    margin-bottom: .5rem;
}
.form-page label span {
    color: rgb(255, 119, 0);
}
.form-page .table-container {
    overflow: scroll;
}
.form-page table {
    min-width: 100%;
    margin-top: 2rem;
    border: 1px solid var(--bg-darkest);
    border-collapse: collapse;
}
.form-page.debts table {
    min-width: 130%;
}

.form-page table th {
    border-bottom: 1px solid var(--bg-darkest);
    font-family: JannaBold;
    color: var(--primary);
}
.form-page table :is(td, th){
    padding: 1.5rem 1rem;
    border-bottom: 1px solid var(--bg-darkest);
    text-align: right;
    font-size: var(--paragraph);
}
/* .form-page table thead tr {
    background: var(--bg-darkest);
} */
.form-page table tbody tr:nth-child(odd){
    background-color: var(--bg-darker);
}
.form-page table tbody button {
    color: var(--bg);
    background: var(--accent) !important;
    transition: .3s ease;
}
.form-page table tbody button:hover {
    background: var(--primary) !important;
}

.form-page .download:hover button {
    background: var(--accent);
}
.form-page .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-page .not-found .image {
    width: 35%;
}
.form-page .not-found .image img {
    width: 100%;
}
.form-page .not-found h1 {
    font-size: calc(var(--title) * 1.5);
    font-family: JannaBold;
}
.form-page .not-found h1 span {
    color: var(--primary);
}

.form-page.add-session .suggessions {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: -1;
    padding: 1rem;
    opacity: 0;
    transition: .4s;
    pointer-events: none;
    font-size: var(--paragraph);
}
.form-page.add-session .suggessions.active {
    opacity: .3;
}


.form-page .message-row {
    text-align: center;
    font-family: var(--paragraph);
    color: rgb(222, 61, 61);
    padding-top: 3rem !important;
    opacity: .7;
}


.form-page input[type='checkbox']{
    display: none
}
.form-page .checkbox-row {
    display: flex;
    gap: .5rem;
    align-items: center;
    color: var(--primary);
    margin: 1rem 0;
}
.form-page .checkbox-row label {
    all: initial;
    font-size: var(--paragraph);
    font-family: JannaRegular;
    color: var(--font);
}
.form-page .checkbox-square {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--bg-darkest);
    border-radius: 3px;
    font-size: 2rem;
}

.form-page .checkbox-container {
    display: flex;
    align-items: center;
    gap: .5rem;
}
.form-page .checkbox {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease;
    border-radius: 4px;
    font-size: 25px;
    border: 1px solid var(--bg-darkest);
    color: var(--bg);
    background: var(--bg-darker);
}
.form-page .checkbox.active {
    background: var(--primary);
}

.patient-info button:hover {
    filter: brightness(110%);
}

.form-page .services-info tr:nth-child(odd),.form-page .services-info tr:nth-child(even){
    background: var(--bg);
}
.form-page .services-info tr.selected {
    background: var(--bg-darker);
    color: var(--primary);
}
.form-page .services-info ul li div:nth-child(1){
    font-family: JannaBold;
}
.form-page .delete-btn-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: .4s;
}
.form-page .delete-btn-icon:hover {
    background: var(--bg-darker);
}
table :is(.no-attend p,.attend p){
    width: fit-content;
    text-align: center;
    padding: .2rem 2rem;
    border-radius: 4px;
}
table .no-attend p {
    color: var(--danger) ;
    background-color: #f437371d;
}
table .attend p {
    color: var(--primary) ;
    background-color: var(--primary-transparent);
}