
.light, :root {
    --title:1.5vw;
    --paragraph:1rem;
    --container:2.5rem;
    --bg:#f2f2f2;
    --bg-darker:hsl(0, 0%, 92%);
    --bg-darkest:hsl(0, 0%, 89%);
    --primary:#228a83;
    --primary-transparent:#228a831d;
    --accent:#BDA16F;
    --font:#4A4A49;
    --danger:#d93131;
}

.dark{
    --title:1.5vw;
    --paragraph:1rem;
    --container:2.5rem;
    --bg:#121212;
    --bg-darker:#2f2f2f;
    --bg-darkest:#3a3a3a;
    --primary:#23bbb1;
    --accent:#BDA16F;
    --font:#f2f2f2;
    --danger:rgb(245, 64, 64);
}

@font-face {
    font-family: JannaRegular;
    src: url('./Assets/Fonts/ArbFONTS-Janna-LT-Regular.ttf');
}
@font-face {
    font-family: JannaBold;
    src: url('./Assets/Fonts/alfont_com_Janna-LT-Bold.ttf');
}



* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body {
    background: var(--bg);
    color: var(--font);
    font-family: JannaRegular;
}

a {
    text-decoration: none;
    color: var(--font);
}

input,textarea {
    font-family: JannaRegular;
}

::-webkit-scrollbar {
    width: 5px;
    background: var(--bg);
}
::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.page-container {
    margin: 2.5rem var(--container);
}

.loading-icon {
    animation: 1s ease forwards infinite loading
}

button {
    font-family: JannaRegular !important;
}
@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
.note {
    background: var(--primary-transparent);
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 5px;
    padding: 1rem;
    width: fit-content;
    margin: .5rem 0;
}
.message {
    color: var(--primary);
    text-align: center;
    margin: 1rem 0;
}