#laserPrompt {
    background: var(--bg);
    border: var(--bg-darker) 1px solid;
    border-radius: 5px;
    padding: 1.5rem;
    position: fixed;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    z-index: 110;
    pointer-events: none;
    opacity: 0;
    transition: .4s ease;
    transform: translateY(20px);
}
#laserPrompt.active {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: all;
}
#laserPrompt h2 {
    font-family: JannaBold;
}
#laserPrompt p {
    font-size: var(--text);
    opacity: .8;
}