#unauthorized {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem var(--container);
}
#unauthorized .image {
    width: 30%;
}
#unauthorized .image img {
    width: 100%;
}
#unauthorized h1 {
    font-size: calc(var(--title) * 2);
    width: 80%;
    text-align: center;
    margin: auto;
}