header {
    border-bottom: 1px solid var(--bg-darkest);
    padding: 2rem;
}
header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}
header .account {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
}
header .account .image {
    width: 40px;
    height: 40px;
}
header .account .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
}
header .path h3 span {
    color: var(--primary);
}