.employee-page .container{
    display: grid;
    grid-template-columns: calc(30% - 1rem) calc(70% - 1rem);
    gap: 1rem;
}
.employee-page .container .profile-details .image {
    height: 340px;
    overflow: hidden;
}
.employee-page .container img {
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    height: 100%;
}

.employee-page .container .profile-details {
    border-left: 1px solid var(--bg-darkest);
    /* padding: 1rem; */
}
.employee-page .container .profile-details ul {
    list-style-type: none;
}
.employee-page .container .profile-details ul li {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: .8rem;
}
.employee-page .container .profile-details ul li:nth-child(odd){
    background: var(--bg-darker);
}
.employee-page .container .profile-details ul li .label {
    font-family: JannaBold;
    color: var(--primary);
}
.employee-page .container .profile-details ul li button {
    width: 100%;
    padding:1rem 1.2rem;
    background: rgb(228, 61, 61);
    color: var(--bg);
    border: none;
    outline: none;
    border-radius: 5px;
    font-family: JannaRegular;
    transition: .4s;
    font-size: var(--paragraph);
}
.employee-page .container .profile-details ul li button:hover {
    filter: brightness(110%);
}
.employee-page table {
    width: 100% !important;
}
.employee-page .total {
    margin-top: 1rem;
}
.employee-page .total span {
    color: var(--primary);
}
.employee-page .section-title {
    font-size: calc(var(--title) * .5);
    margin-top: 1rem;
}
.employee-page .sessionFilter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    width: 50%;
}
.employee-page .options ul {
    list-style:  none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--bg-darkest);
}
.employee-page .options ul li {
    font-size: var(--paragraph);
    padding: 1rem 2rem;
    transition: .4s;
    border-bottom: 1px solid var(--bg-darkest);
}
.employee-page .options ul li.active {
    border-bottom: 1px solid var(--primary);
    color: var(--primary);
}