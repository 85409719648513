#logoutAlert {
    position: fixed;
    inset: 0;
    margin: auto;
    background: var(--bg);
    padding: 1.5rem;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    z-index: 100;
    border: 1px solid var(--bg-darker);
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    transition: .4s;
}
#logoutAlert.active {
    pointer-events: all;
    transform: translateY(0px);
    opacity: 1;
}
#logoutAlert h3 {
    text-align: right;
    font-size: var(--title);
    font-size: mainARBold;
}
#logoutAlert .btns {
    display: flex;
    gap: .5rem;
    margin-top: 1rem;
    justify-content: start;
}
#logoutAlert button {
    padding: .8rem 1.6rem;
    font-size: var(--text);
    border-radius: 4px;
    outline: none;
    font-family: mainAR;
    border: none;
    transition: .4s;
}
#logoutAlert button.yes {
    color: var(--bg);
    background: rgb(247, 48, 48);
}
#logoutAlert button.yes:hover {
    filter: brightness(110%);
}
#logoutAlert button.no {
    color: rgb(247, 48, 48);
    border: 1px solid var(--bg-darkest);
}
#logoutAlert button.no:hover {
    color: var(--bg);
    background: rgb(247, 48, 48);
}