.sell-product-page .quantity-container  {
    display: grid;
    grid-template-columns: 60px 100px 60px
}
.sell-product-page .quantity-container  input {
    border-radius: 5px !important;
    text-align: center;
}
.sell-product-page .quantity-container  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}
.sell-product-page .loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #228a8327;
    position: fixed;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
}
.sell-product-page .loading.active {
    opacity: 1;
    pointer-events: all;
}