.edit-receipt input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--bg-darkest);
    filter: brightness(60%);
    font-size: calc(var(--title) * 1.33);
    outline: none;
    color: var(--font);
    font-family: JannaBold;
    font-weight: 100;
}
.edit-receipt input:focus {
    width: 100%;
    background-color: transparent;
    border-color: var(--primary);
    filter: brightness(100%);
}
.edit-receipt input.small {
    font-size: var(--paragraph);
    width: 70%;
}

.edit-receipt .delete-btn {
    background: var(--danger) !important;
}